<template>
  <div id="container">
    <el-header style="height:80px">
      <header class="layout-header">
        <h1 class="layout-logo" @click="that.$router.push('/home')"></h1>
        <ul>
          <li 
              @click="that.$router.push('/home')" 
              :class="{active:that.$router.history.current.fullPath ==='/home'}"
              >
              首页
          </li>
          <li 
              @click="that.$router.push('/housekeeping')" 
              :class="{active:that.$router.history.current.fullPath ==='/housekeeping'}"
              >
              家政服务
              </li>
          <li 
              @click="that.$router.push('/training')" 
              :class="{active:that.$router.history.current.fullPath ==='/training'}">
              培训服务
              </li>
          <li 
              @click="that.$router.push('/appraisal')" 
              :class="{active:that.$router.history.current.fullPath ==='/appraisal'}">
              鉴定服务
          </li>
          <li 
              @mouseleave="move"
              @mouseover="change"
              :class="flag?'active':''"
              class="li">
              
              机构入驻
              <div  @mouseover="change"  :class="flag?'settle':'' ">
                <div class="settle_header">
                  <span @click="jump(0)" >商家入驻申请</span>
                  <span @click="login(0)">商家登录</span>
                </div>
                <div class="settle_header">
                  <span @click="jump(1)" >培训机构入驻申请</span>
                  <span @click="login(1)">培训机构登录</span>
                </div>
                <div class="settle_header">
                  <span @click="jump(2)" >评价机构入驻申请</span>
                  <span @click="login(2)">评价机构登录</span>
                  <span @click="login(3)">教授登录</span>
                </div>
              </div>
          </li>
          <li 
              @click="that.$router.push('/team')" 
              :class="{active:that.$router.history.current.fullPath ==='/team'}">
              关于我们
              </li>
          <li 
              @click="that.$router.push('/download')" 
              :class="{active:that.$router.history.current.fullPath ==='/download'}">
              下载APP
              </li>
        </ul>
      </header>
    </el-header>
    <el-main class="layout-main">
      <div class="layout-tool" v-show="asideTool">
        <!-- <div class="tool-online">
          <figure></figure>
          <figcaption>在线客服</figcaption>
        </div> -->
        <el-tooltip class="item tool-tel" effect="light" content="028-86644718" placement="left">
          <div slot="content" class="tel-content">客服电话<br/><br/>400-6046-400<br/><br/>8:00~22:00 7*24小时客服专线</div>
          <el-button>
            <figure></figure>
            <figcaption>客服电话</figcaption>
          </el-button>
        </el-tooltip>
        <div class="tool-download" @click="that.$router.push('/download')">
          <figure></figure>
          <figcaption>下载</figcaption>
        </div>
        <div class="tool-top" @click="changeHash('#container')">
          <figure></figure>
          <figcaption>返回顶部</figcaption>
        </div>
      </div>
      <slot />
    </el-main>
    <el-footer style="height:260px">
      <footer class="layout-footer">
        <div class="layout-contact">
          <span class="layout-contact-title">
            联系我们
          </span>
          <span class="layout-contact-num">
            400-6046-400
          </span>
          <span class="layout-contact-cont">
            8:00~22:00 7*24小时客服专线
          </span>
        </div>
        <div class="layout-cooperation">
          <span class="layout-cooperation-title">
            解决方案&商务合作
          </span>
          <span class="layout-cooperation-mail">
            <i></i>
            tiger@maygin.com
          </span>
          <span class="layout-cooperation-address">
            <i></i>
            成都市青羊区清江中路20号
          </span>
        </div>
        <div class="layout-download" >
          <div>
            <div class="layout-QR"></div>
            <span>关注公众号</span>
          </div>
        </div>
        <span style="font-size: 16px;">&copy;2021老马爱家版权所有- 
           <a style="color: #1AB1FF;" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022028729号-1</a>
            - - 
            <a  style="color: #1AB1FF;" href="https://laomaaijia.com/" target="_blank">laomaaijia.com</a>
            </span>
      </footer>
    </el-footer>
  </div>
</template>

<script>
export default {
  data(){
    return{
      that:this,
      asideTool:true,
      flag: false,
      urlFlag: false,
 
    }
  },
  mounted() {

  },
  methods:{
    changeHash(idname) {
        document.querySelector(idname).scrollIntoView(true);
    },
    jump(e) {
      window.location.href = 'https://b.laomaaijia.com/shopadmin/register?id='+e
    },
    change() {
      this.flag = true
    },
    move() {
      this.flag = false
    },
    login(e) {
      switch(e) {
        case 0 : 
          window.location.href = 'https://b.laomaaijia.com/shopadmin/login'
          break;
        case 1 : 
          window.location.href = 'https://train.laomaaijia.com'
          break;
        case 2 : 
          window.location.href = 'https://appraisal.laomaaijia.com'
          break;
        case 3 : 
          window.location.href = 'https://professor.laomaaijia.com'
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../style/layout.less";
.item{
  width: 60px;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  outline: none;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  border-radius: 0;
  &:hover{
      background-color: #1AB1FF;
      transition: .3s;
      border-left: 1px solid #1AB1FF;
      border-top: 1px solid #1AB1FF;
      cursor: pointer;
      figcaption{
          color: #fff;
      }
      &:last-child{
          border-bottom: 1px solid #1AB1FF;
      }
  }
  figure{
      width: 40px;
      height: 40px;
  }
  figcaption{
      font-size: 12px;
      color: #666;
  }
}
.tel-content{
  width: 240px;
  height: 100px;
  text-align: center;
  font-size: 16px;
}
</style>
