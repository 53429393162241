/***********************************************************************
* 全局组件、过滤器、指令文件
* created by svan_liu@163.com on 2021-04-14
***********************************************************************/

import Layout from './Layout'

const plugin = {
    install(Vue) {
        Vue.component('Layout', Layout)
    }
}

export default plugin