import Vue from 'vue'

import App from './views'

import router from './routers'

import './style/index.less'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import GlobalComponent from './components'
Vue.use(GlobalComponent)

import store from './store'

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.config.productionTip = false
