<template>
    <div class="application-container">
        <router-view/>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
    @import '../style/index.less';
</style>