import Vue from 'vue'
import Router from 'vue-router'
import Home from './home'
import News from './news'
import Housekeeping from './housekeeping'
import Training from './training'
import Appraisal from './appraisal'
import Team from './team'
import Download from './download'
import NewsCenter from './newsCenter'
Vue.use(Router)

const Children = () => import('../views/children')

const router = new Router({
    routes:[
        {path:'/',redirect:'/home'},
        {path:'/home',component:Children,
        children:[
            ...Home,
            ...NewsCenter,
            ...News,
            ...Housekeeping,
            ...Training,
            ...Appraisal,
            ...Team,
            ...Download
          ]
        },
    ],
})


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router