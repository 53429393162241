export default {
    namespaced: true,
    state:{
        title:'',
        image:'',
        title:''
    },
    getters:{
        getTitle: state => state.title,
        getImage: state => state.image,
        getContent: state => state.content
    },
    actions:{
        setTitle({commit},title){
            commit('mutationTitle',title)
        },
        setImage({commit},title){
            commit('mutationImage',image)
        },
        setContent({commit},content){
            commit('mutationContent',content)
        }
    },
    mutations:{
        mutationTitle(state,title){
            // console.log(title);
            state.title = title
        },
        mutationImage(state,image){
            // console.log(image);
            state.image = image
        },
        mutationContent(state,content){
            // console.log(content);
            state.content = content
        }
    }
}